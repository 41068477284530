import React, { useEffect, useState } from 'react'

import Page from '../page'
import { Form } from '../../components/form'
import { NavInExperience } from '../../components/nav'
import { experiences as experiencesApi } from '../../lib/api'
import { apiCache } from '../../stores'

export const Popups = props => {
  const { experienceUid, siteUid } = props.match.params
  const [experience, setExperience] = useState(apiCache.experiences.get(experienceUid))

  useEffect(() => {
    if (!experience) experiencesApi.get(experienceUid).then(experience => setExperience(experience))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience])

  const save = popups => {
    if (experienceUid) experiencesApi.update(experienceUid, { _id: experienceUid, popups })
  }

  return !experience ? null : (
    <Page>
      <NavInExperience />
      <div id="content">
        <Form
          formPath="popups"
          initialFormData={experience?.popups}
          onSubmit={save}
          formContext={{ experienceUid, siteUid }}
          submitButtonText="Enregistrer"
        />
      </div>
      <div id="app-preview" />
    </Page>
  )
}
